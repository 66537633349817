// Import and register all your controllers from the importmap via controllers/**/*_controller
import { application } from "./application"
import { registerControllers } from 'stimulus-vite-helpers'
import { Autocomplete } from 'stimulus-autocomplete'
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })

registerControllers(application, controllers)
application.register('autocomplete', Autocomplete)
// eagerLoadControllersFrom("controllers", application)
